

.filter-buttons {
    display: flex;
    justify-content: center; /* Butonları ekranın ortasında hizalar */
    flex-wrap: wrap; /* Küçük ekranlarda butonların alt alta geçmesine izin verir */
    gap: 15px; /* Butonlar arası boşluk */
  }
  
  .filter-buttons button {
    padding: 10px 20px;
    border: none;
    background-color: #54545489; /* Buton arka planı */
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 20px; /* Yuvarlatılmış köşeler */
  }
  
  .filter-buttons button:hover {
    background-color: #292929b4; /* Hover efekti */
  }
  
  .filter-buttons button.active {
    background-color: #000000; /* Aktif buton rengi */
  }
  
  .design-grid {
    display: grid;
    gap: 1rem; /* Tasarımlar arası boşluk */
    justify-items: center;
    padding: 10rem;
  }

  .design-cover-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* İki sütunlu grid */
    gap: 1rem; /* Tasarımlar arası boşluk */
    justify-items: center;
    padding: 10rem;
  }
  
  .design-item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
  }

  .design-cover-item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    height: 45vh;
    width: 100%; /* Div'in genişliğini %100 yap */
  }
  
  .design-item img {
    display: block;
    width: 100%;
    transition: opacity 0.3s ease;
  }

  .design-cover-item img {
    display: block;
    width: 100%;
    height: 100%; /* Yüksekliği %100 yap */
    object-fit: cover; /* Görselin orantılı bir şekilde doldurulmasını sağlar */
    transition: opacity 0.3s ease;
  }
  
  .item-hover {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2.2rem;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .design-item:hover img {
    opacity: 0.05;
  }
  
  .design-item:hover .item-hover {
    opacity: 1;
  }

  .design-cover-item:hover img {
    opacity: 0.05;
  }
  
  .design-cover-item:hover .item-hover {
    opacity: 1;
  }
  
  .more-items {
    font-size: 2rem;
    padding: 10px 40px;
    border-radius: 8px;
    border: solid transparent;
  }
  
  /* Responsive Tasarım */
  @media (max-width: 768px) {
    .filter-buttons {
      gap: 10px;
    }
  
    .design-grid {
      grid-template-columns: 1fr; /* Küçük ekranlarda tek sütun */
      padding: 1rem;
    }

    .design-cover-grid {
      padding: 1rem;
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 1024px) {
    .design-grid {
      grid-template-columns: 1fr; /* Küçük ekranlarda tek sütun */
      padding: 4rem;
    }

    .design-cover-grid {
      padding: 4rem;
      grid-template-columns: 1fr;
    }

    .design-cover-item {
      height: 100%;
    }
  }

  @media (max-width: 480px) {
    .filter-buttons button {
      padding: 5px 10px;
      font-size: 1rem;
    }

    .design-grid{
      padding: 1rem;
      margin-top: 1rem;
    }

    .design-cover-grid {
      padding: 1rem;
      grid-template-columns: 1fr;
    }
  }