.contact {
    width: 80%; /* Set width to 80% */
    margin: 0 auto; /* Center the contact section */
    margin-top: 5%;
    border: 1px solid rgba(0, 0, 0, 0.1); /* Light border */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 20px; /* Add padding inside the contact box */
    color: white;
    background-color: #24242493; 
  }
  
  .top {
    text-align: center; /* Center text in the top section */
    margin-bottom: 20px; /* Space between top and bottom */
    width: 50%;
    margin: 0 auto;
    padding: 30px;
  }
  
  .bottom {
    display: flex; /* Use flexbox for the bottom section */
    justify-content: space-between; /* Space items evenly */
    align-items: center; /* Center items vertically */
    padding: 50px;
  }
  
  .phone, .email, .linkedin {
    text-align: center; /* Center text within each item */
    flex: 1; /* Allow items to grow equally */
    padding: 10px; /* Add padding for spacing */
    font-size: 1.2rem;
  }
  
  .phone i, .email i, .linkedin i {
    font-size: 24px; /* Icon size */
    margin-bottom: 10px; /* Space between icon and text */
  }

  a{
    text-decoration: none;
    color: rgb(0, 149, 255);
  }
  

  @media (max-width: 768px) { 
    .top{
      width: 80%;
      padding: 20px;
    }

    .phone, .email, .linkedin {
      font-size: 0.8rem;
      margin-bottom: 20px;
    }

    .bottom {
      flex-direction: column;
      padding: 20px;
    }
  }