.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 5rem;
    background-color: #313131;
    position: relative;
    margin-bottom: 4rem;
  }
  
  .navbar a {
    text-decoration: none;
    color: #ffffff;
    margin-left: 20px;
  }
  
  .navbar a:first-child {
    margin-left: 0;
  }
  
  .navbar .navbar-left {
    display: flex;
    gap: 20px; /* Sol taraftaki linkler arası boşluk */
    font-size: 1.8rem;
    font-weight: 500 !important;
  }
  
  .navbar .navbar-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); /* Şevval Yitmen yazısını tam ortalar */
    font-size: 3rem;
  }

  @media (max-width: 1024px) {
    .navbar {
        padding: 10px 2rem;
    }

    .navbar .navbar-center {
      font-size: 1.5rem; /* Font boyutu küçültülür */
    }
  
    .navbar .navbar-left {
      gap: 10px; /* Linkler arasındaki boşluk küçültülür */
      font-size: 1rem;
    }
  }
  
  @media (max-width: 768px) {
    .navbar {
        padding: 10px 2rem;
    }

    .navbar .navbar-center {
      font-size: 1.5rem; /* Font boyutu küçültülür */
    }
  
    .navbar .navbar-left {
      gap: 10px; /* Linkler arasındaki boşluk küçültülür */
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .navbar {
        padding: 10px 1rem;
    }

    .navbar .navbar-center {
      font-size: 1rem;
    }
  
    .navbar .navbar-left {
      gap: 10px; /* Linkler arasında dikey boşluk */
      justify-content: space-between;
      font-size: .9rem;
      width: 100%;
    }
  }
 